import {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {getPollResponseByUserApi, submitPollResponseByUserApi, getPollByShortTitleApi, getNextCatApi} from '../api/index';
import TakePollComp2 from "./TakePollComp2";
import Container from "react-bootstrap/Container";
import {getToken, handleAuthenticationError} from "../api/userAuth";


export default function TakePoll() {

    const navigate = useNavigate();
    const {short_title} = useParams();
    const [pollDetails, setPollDetails] = useState(null);
    const [question, setQuestion] = useState(null);
    const [isCat, setIsCat] = useState(false);
    const [qIdx, setQIdx] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        async function fetchData() {
            // Redirect user to their previous response, if test already taken.
            try {
                const data = {short_title: short_title, token: getToken()};
                console.log(data);
                await getPollResponseByUserApi(data);
                navigate(`/view/${short_title}`);
            } catch (error) {
               console.log(error); 
            }
            try {
                const response = await getPollByShortTitleApi(short_title);
                setQuestion(response.data.questions[0])
                setQuestions(response.data.questions);
                setIsCat(response.data.cat_mode);
                setPollDetails({
                    title: response.data.title,
                    details: response.data.details,
                    author: response.data.author,
                    short_title: response.data.short_title,
                });

            } catch (error) {
                console.error('Error fetching polls:', error);
            }
        }

        void fetchData();
    }, []);

    async function getNextQuestion(answer) {
        try {
            const data = {
                answer: answer, 
                token: getToken(),
                short_title: short_title,
            };
            console.log(data);
            const response = await getNextCatApi(data);
            console.log(response);
            if(response.data.message == 'STOP')
            {
                navigate(`/view/${pollDetails.short_title}`);
            }
            setQuestion(response.data.new_question)
        } catch (error) {
            handleAuthenticationError(error);
            console.error('Error fetching polls:', error);
        }
    };

    const submitClick = async (currentAnswers) => {
        const answerData = [];
        for (let i = 0; i < currentAnswers.length; i++) {
            const q = {
                question_id: currentAnswers[i].q_id,
                question_type: "text",
                answer: currentAnswers[i].reply || currentAnswers[i].selections,
            }
            answerData.push(q);
        }
        const data = {
            short_title: pollDetails.short_title,
            results: answerData,
            token: getToken(),
        }
        try {
            await submitPollResponseByUserApi(data);
            navigate(`/view/${pollDetails.short_title}`);
        } catch (error) {
            console.log(error);
            handleAuthenticationError(error);
            alert('Submission failed!');
            window.location.reload();
        }
    }

    const newQuestion = async (answer) => {
        if (isCat) await getNextQuestion(answer);
        else {
            setAnswers([...answers, answer]);
            const inc = qIdx + 1;
            if (inc === questions.length) {
                await submitClick([...answers, answer]);
            }
            setQuestion(questions[inc]);
            setQIdx(inc);
        }
    };

    return (
        <Container>
            <div>
                {pollDetails ?
                    <TakePollComp2 pollDetails={pollDetails} question={question} newQuestion={newQuestion}/> :
                    <div>Loading...</div>}
            </div>
        </Container>
    )
}