import {useState} from "react";
import {Link} from 'react-router-dom';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from "@mui/material";
import '../css/choice.css'


export default function TakePollComp2({pollDetails, question, newQuestion}) {

    // Initialize answers array to match with questions
    const [selections, setSelections] = useState([]);
    const [answer, setAnswer] = useState(
        {
            reply: '',
            choice_id: null,
        }
    );

    function renderRadioChoice(choice) {
        return (
            <FormControlLabel key={choice.id}
                              label={<span className="choice-content"
                                           dangerouslySetInnerHTML={{__html: choice.text}}/>}
                              control={
                                  <Radio
                                      checked={answer === null ? false : answer.choice_id === choice.id}
                                      onChange={() => {
                                          setAnswer({
                                              reply: choice.text,
                                              choice_id: choice.id,
                                              q_id: question.id,
                                          })
                                      }}/>
                              }/>
        )
    }

    function renderCheckboxChoice(choice) {
        return (
            <FormControlLabel key={choice.id}
                              label={<span className="choice-content"
                                           dangerouslySetInnerHTML={{__html: choice.text}}/>}
                              control={
                                  <Checkbox checked={selections.includes(choice.id)}
                                            onChange={() => {
                                                if (selections.includes(choice.id)) {
                                                    // Remove choice.id from selections
                                                    setSelections(selections.filter(id => id !== choice.id));
                                                } else {
                                                    // Add choice.id to selections
                                                    setSelections([...selections, choice.id]);
                                                }
                                            }}/>
                              }/>
        )
    }

    function renderCurrentQuestion() {
        console.log(question);
        return (
            <Paper elevation={5} sx={{margin: 2, padding: 2}}>
                <h6>Question</h6>
                <div
                    dangerouslySetInnerHTML={{__html: question.prompt}}
                />
                {
                    question.type === "Long Answer" &&
                    <TextField variant="outlined" fullWidth multiline maxrows={30}
                               label="Reply (Long Answer)" value={answer.reply}
                               onChange={(event) => {
                                   setAnswer({
                                       reply: event.target.value,
                                       choice_id: null,
                                       q_id: question.id,
                                   });
                               }}/>
                }
                {
                    question.type === "Short Answer" &&
                    <TextField variant="outlined" fullWidth label="Reply (Short Answer)"
                               value={answer.reply}
                               onChange={(event) => {
                                   setAnswer({
                                       reply: event.target.value,
                                       choice_id: null,
                                       q_id: question.id,
                                   });
                               }}/>
                }
                {
                    question.type === "Multiple Choice" && <>
                        {
                            question.multi && <FormGroup>
                                {
                                    question.choices.map((choice) => {
                                        return renderCheckboxChoice(choice);
                                    })
                                }
                            </FormGroup>
                        }
                        {
                            !question.multi && <FormControl>
                                <RadioGroup>
                                    {
                                        question.choices.map((choice) => {
                                            return renderRadioChoice(choice);
                                        })
                                    }
                                </RadioGroup>
                            </FormControl>
                        }
                    </>
                }
            </Paper>
        )
    }

    function renderNextButton() {
        return <Button variant="contained" onClick={() => {
            let newAnswer = {...answer};
            newAnswer.q_id = question.id;
            if (!question.multi) {
                newQuestion(newAnswer);
            } else {
                let sels = [];
                for (let i = 0; i < question.choices.length; i++) {
                    sels.push({
                        ch_id: question.choices[i].id,
                        value: selections.includes(question.choices[i].id),
                    });
                }
                newQuestion({
                    selections: sels,
                    q_id: question.id,
                });
            }
            setAnswer({
                reply: '',
                choice_id: null,
            });
        }} sx={{width: "fit-content"}}>Next</Button>
    }

    return (
        <Stack direction="column" spacing={2} sx={{margin: 2, padding: 2}}>
            <h1>{pollDetails.title}</h1>
            <p>{pollDetails.details}</p>
            <hr/>
            {renderCurrentQuestion()}
            {renderNextButton()}
            <Link to="/">Back to Home</Link>
        </Stack>
    )
}