import {randomString} from "./utils";

export function handleAuthenticationError(error) {
    if (error.response.status === 401) {
        if (localStorage.getItem("email")) {
            logOutUser();
            window.location = "/signin/";
        } else {
            return 0;
        }
    }
}

export function loginUser(email, data) {
    localStorage.setItem('email', email);
    localStorage.setItem('authToken', data.token);
    localStorage.setItem('isAdmin', data.admin);
}

export function logOutUser() {
    localStorage.removeItem("email");
    localStorage.removeItem("authToken");
    localStorage.removeItem("isAdmin");
}

export function isLoggedIn() {
    return localStorage.getItem('authToken') !== null;
}

export function isAdmin() {
    return localStorage.getItem('isAdmin') === "true";
}

export function getToken() {
    return localStorage.getItem("authToken");
}

export function getAnonymousKey() {
    let anonymousKey = localStorage.getItem('anonymousKey');
    if (anonymousKey) return anonymousKey;
    anonymousKey = randomString(20);
    localStorage.setItem("anonymousKey", anonymousKey);
    return anonymousKey;
}