import React, { useState, useEffect } from 'react';
import { renderPost } from '../api/index';
import { useParams } from 'react-router-dom';
import { getStaticPageByName } from '../api/index';
import "../css/Michels.css"
import EditStatic from './EditStatic';
import { isAdmin } from '../api/userAuth';


export default function StaticPage() {
    const { page_name } = useParams();
    const [content, setContent] = useState(null);
    useEffect(() => {
        const getPosts = async () => {
            try {
                const response = await getStaticPageByName(page_name);
                console.log(response.data);
                setContent(response.data.content);
                console.log(response);
              } catch (error) {
                console.error('Error:', error);
              }

        };
        getPosts();
      }, []);


     return (
        <div className='Michels'>
            <div className='Michels-text'>
                <div 
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: content }} 
                />
            </div>
            { isAdmin() &&
            <EditStatic page_name={page_name}/>
            }   
        </div>
    );
}
