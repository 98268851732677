export const sxCenter = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

// Make id of given length - from stackoverflow
export function makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }

    return result;
}

export function randomString(len) {
    let str = "";                                // String result
    for (let i = 0; i < len; i++) {              // Loop `len` times
        let rand = Math.floor(Math.random() * 62); // random: 0..61
        const charCode = rand += rand > 9 ? (rand < 36 ? 55 : 61) : 48; // Get correct charCode
        str += String.fromCharCode(charCode);      // add Character to str
    }
    return str; // After all loops are done, return the concatenated string
}

