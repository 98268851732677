import {Stack} from "@mui/material";
import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Card} from "react-bootstrap";
import {getPollByShortTitleApi, removePollResponseByUserApi, getPollResponseByUserApi} from "../api/index";
import {getToken, handleAuthenticationError} from "../api/userAuth";
import {BellCurve} from "./BellCurve";

export default function UserResponse() {
    const navigate = useNavigate();
    const {short_title} = useParams();
    const [poll, setPoll] = useState(null);
    const [pollResults, setPollResults] = useState(null);
    const [pollScores, setPollScores] = useState(null);

    const resetTest = async () => {
        try {
            await removePollResponseByUserApi({
                poll_id: short_title,
                token: getToken(),
            });
            navigate(`/poll/${poll.short_title}`);
        } catch (error) {
            handleAuthenticationError(error);
            console.error('Error fetching results:', error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            const data = {
                short_title: short_title,
                token: getToken(),
            };
            try {
                
                const response2 = await getPollResponseByUserApi(data);
                console.log(response2.data)
                setPollResults(response2.data.results);
                setPollScores(response2.data.scores);
                const response1 = await getPollByShortTitleApi(short_title);
                setPoll(response1.data);
                console.log(response1.data)
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching results:', error);
            }
        }

        void fetchData();
    }, []);

    return (
        poll && pollResults && (
            <Stack sx={{display: "flex", alignItems: "center"}}>
                <h2>Your Response To:</h2>
                <h1>{poll.title}</h1>
                <Stack spacing={2} sx={{width: 3 / 4}}>
                    {
                        poll.questions.map((question, index) => {
                            let answers = pollResults.filter(a => a[0] === question.id).map(a => a[1]);
                            return (
                                <Card key={index}>
                                    <Card.Body>
                                        <Card.Title>
                                            <div dangerouslySetInnerHTML={{__html: question.prompt}}/>
                                        </Card.Title>
                                        {answers.map((answer, answerIndex) => (
                                            <div key={answerIndex}>{<span className="choice-content"
                                                                          dangerouslySetInnerHTML={{__html: answer}}/>}</div>
                                        ))}
                                    </Card.Body>
                                </Card>
                            );
                        })
                    }
                </Stack>
                <BellCurve scores={pollScores}/>
                <br/>
                <Link to={`/poll/${short_title}`} onClick={(e) => {
                    e.preventDefault();
                    void resetTest();
                }}>Retake Test</Link>
                <Link to="/">Back to Home</Link>
            </Stack>
        )
    )
}