import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {Button, Divider, Paper, Stack, TextField, FormControlLabel, Checkbox} from "@mui/material";
import {makeId, sxCenter} from "../api/utils";
import Container from "react-bootstrap/Container";
import {submitNewPollApi, uploadImageFile, getPagesApi} from "../api/index";
import {getToken, handleAuthenticationError} from "../api/userAuth";
import MCQuestion from "./MCQuestion";
import ShortAnswerQuestion from "./ShortAnswerQuestion";
import LongAnswerQuestion from "./LongAnswerQuestion";
import Form from 'react-bootstrap/Form';
import Papa from 'papaparse';

export default function CreatePoll() {

    const [poll, setPoll] = useState({
        author: "",
        title: "",
        details: "",
        date: "",
        cat_mode: false,
    });

    const [questions, setQuestions] = useState([]);
    const [file, setFile] = useState(null);
    const [postPages, setPostPages] = useState([]);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        const getPages = async () => {
            try {
                const response = await getPagesApi();
                console.log(response.data);
                setPages(response.data);
              } catch (error) {
                console.error('Error:', error);
              }
    
        };
        getPages();
      }, []);

    useEffect(() => {
        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    const processedQuestions = processCSVData(result.data);
                    setQuestions(processedQuestions);
                },
                header: false,
                skipEmptyLines: true
            });
        }
    }, [file]); // This effect runs only when the 'file' state changes

    const handleCheckboxChange = (pageName) => {
        if (postPages.includes(pageName)) {
          setPostPages(postPages.filter(name => name !== pageName));
        } else {
          setPostPages([...postPages, pageName]);
        }
      };
    

    const handleFileSelect = (event) => {
        setFile(event.target.files[0]);
    };

    const processCSVData = (csvData) => {
        return csvData.slice(1).map(q => {
            const choices = parseCSVColumn(q[2], false);
            const scores = parseCSVColumn(q[3], true);
            let actual_choices = [];
            for (let i = 0; i < choices.length; i++)
            {
                actual_choices.push({
                    text: choices[i],
                    score: parseFloat(scores[i]),
                    id: makeId(5),
                });
            }
            return {
                local_id: makeId(10),
                type: q[0],
                prompt: q[1],
                choices: actual_choices,
                multi: q[4] === 'True',
                discrimination: parseFloat(q[5]),
                difficulty: parseFloat(q[6])
            };
        });
    };
    
    // Helper function to parse a CSV column that contains an array
    const parseCSVColumn = (column, isNumeric) => {
        try {
            // Replace single quotes with double quotes and remove square brackets
            const formattedColumn = column.replace(/'/g, '"').replace(/^\[|\]$/g, '');
            // Split the string into an array based on comma separation
            return formattedColumn.split(',').map(item => {
                item = item.trim();
                return isNumeric ? parseInt(item, 10) : item;
            });
        } catch (error) {
            console.error('Error parsing CSV column:', error);
            return [];
        }
    };

    // Thumbnail image selection.
    const changeFile = event => {
        let selectedFile = event.target.files[0];
        if (!selectedFile) return;

        async function uploadImage() {
            try {
                const formData = new FormData();
                formData.append("token", getToken());
                formData.append("image", selectedFile, selectedFile.name);
                const response = await uploadImageFile(formData);
                console.log(response);
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error uploading image file:', error);
            }
        }

        void uploadImage();
        let newPoll = {...poll};
        newPoll.thumbnail = selectedFile.name;
        setPoll(newPoll);
    };


    // Text Field Changes

    const changeTitle = (event) => {
        let newPoll = {...poll};
        newPoll.title = event.target.value;
        setPoll(newPoll);
    }

    const changeDetails = (event) => {
        let newPoll = {...poll};
        newPoll.details = event.target.value;
        setPoll(newPoll);
    }

    const toggleCat = (event) => {
        let newPoll = {...poll};
        newPoll.cat_mode = !poll.cat_mode;
        setPoll(newPoll);
    };

    // Button Clicks

    const choiceClick = () => {
        setQuestions(questions.concat({
            local_id: makeId(10),
            type: "Multiple Choice",
            prompt: "",
            multi: false,
            choices: []
        }));
    }

    const longClick = () => {
        setQuestions(questions.concat({local_id: makeId(10), type: "Long Answer", prompt: ""}));
    }

    const shortClick = () => {
        setQuestions(questions.concat({local_id: makeId(10), type: "Short Answer", prompt: ""}));
    }

    const submitClick = async () => {
        // Create a new array of questions without the 'local_id' property
        let questionsWithoutLocalId = questions.map(({local_id, ...rest}) => rest);
        if (!poll.cat_mode)
        {
            questionsWithoutLocalId = questionsWithoutLocalId.map( ({difficulty, discrimination, ...rest}) => rest );
        }
        const toSend = {
            title: poll.title,
            details: poll.details,
            thumbnail: poll.thumbnail,
            questions: questionsWithoutLocalId,
            cat_mode: poll.cat_mode,
            token: getToken(),
            pages: postPages,
        };
        try {
            const re = await submitNewPollApi(toSend);
            alert('Submission Successful!');
            window.location.reload();
        } catch (error) {
            handleAuthenticationError(error);
            console.log(error);
            alert('Submission Failed! Check account privileges.');
            window.location.reload();
        }

    }

    const deleteQuestion = (oldQuestion) => {
        let newQuestions = [...questions];
        let index = newQuestions.findIndex(question => question.local_id === oldQuestion.local_id);
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    }

    const updateQuestion = (updatedQuestion) => {
        // gets a question with a local_id and anything else. Replaces the one with that local_id with this
        let newQuestions = [...questions];
        let index = newQuestions.findIndex(q => q.local_id === updatedQuestion.local_id);
        newQuestions[index] = updatedQuestion;
        setQuestions(newQuestions);
    }

    return (
        <Container>
            <Stack sx={sxCenter} direction="column" spacing={1}>
                <h1 className="mt-4">Create New Poll</h1>
                <Paper sx={{...sxCenter, width: 3.5 / 4}}>
                    <Stack spacing={2} direction="column" divider={<Divider/>}
                           sx={{display: "flex", justifyContent: "center", width: 3 / 3, margin: 2}}>
                        <TextField variant="outlined" label="Title" value={poll.title} onChange={changeTitle}/>
                        <TextField variant="outlined" label="Details" value={poll.details} onChange={changeDetails}/>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Choose poll thumbnail:</Form.Label>
                            <Form.Control type="file" accept=".bmp,.gif,.jpeg,.jpg,.png,.svg" onChange={changeFile}/>
                        </Form.Group>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={poll.cat_mode}
                                    onChange={toggleCat}
                                />
                            }
                            label="Cognitive Adaptive Testing Mode"
                        />
                        {
                            questions.map((question) => {
                                if (question.type === 'Multiple Choice') {
                                    return (
                                        <Paper elevation={5}>
                                            <Stack direction="column" spacing={1} sx={{padding: 2}}>
                                                <MCQuestion updateFunc={updateQuestion} deleteFunc={deleteQuestion}
                                                            question={question} cat={poll.cat_mode}/>
                                            </Stack>
                                        </Paper>
                                    )
                                }
                                if (question.type === 'Short Answer') {
                                    return (
                                        <Paper elevation={5}>
                                            <Stack direction="column" spacing={1} sx={{padding: 2}}>
                                                <ShortAnswerQuestion updateFunc={updateQuestion}
                                                                     deleteFunc={deleteQuestion} question={question}/>
                                            </Stack>
                                        </Paper>
                                    )
                                }
                                if (question.type === 'Long Answer') {
                                    return (
                                        <Paper elevation={5}>
                                            <Stack direction="column" spacing={1} sx={{padding: 2}}>
                                                <LongAnswerQuestion updateFunc={updateQuestion}
                                                                    deleteFunc={deleteQuestion} question={question}/>
                                            </Stack>
                                        </Paper>
                                    )
                                }
                            })
                        }
                        <Stack direction="row" spacing={2} sx={sxCenter}>
                            <h6>Add Question:</h6>
                            <Button variant="contained" onClick={choiceClick}>Multiple Choice</Button>
                            <Button variant="contained" onClick={shortClick}>Short Answer</Button>
                            <Button variant="contained" onClick={longClick}>Long Answer</Button>
                        </Stack>
                        <Button variant="contained" sx={{width: "fit-content"}} onClick={submitClick}>Submit
                            Poll</Button>
                            <Button variant="contained" sx={{width: "fit-content"}} onClick={() => {
                                 let questionsWithoutLocalId = questions.map(({local_id, ...rest}) => rest);
                                 if (!poll.cat_mode)
                                 {
                                     questionsWithoutLocalId = questionsWithoutLocalId.map( ({difficulty, discrimination, ...rest}) => rest );
                                 }
                                 const toSend = {
                                    title: poll.title,
                                    details: poll.details,
                                    thumbnail: poll.thumbnail,
                                    questions: questionsWithoutLocalId,
                                    cat_mode: poll.cat_mode,
                                    token: getToken(),
                                    pages: postPages,
                                };
                                 console.log(toSend);
                            }}>Debug 
                            Poll</Button>
                        <Form.Group controlId="formFileCSV" className="mb-3">
                            <Form.Label>Upload Questions CSV:</Form.Label>
                            <Form.Control type="file" accept=".csv" onChange={handleFileSelect}/>
                        </Form.Group>
                        {pages.filter(page => page.type === 'dynamic').map(page => (
                        <div key={page.id}>
                            <input 
                            type="checkbox" 
                            id={page.name} 
                            name={page.name} 
                            checked={postPages.includes(page.name)}
                            onChange={() => handleCheckboxChange(page.name)}
                            />
                            <label htmlFor={page.name}>{page.name}</label>
                        </div>
                        ))}
                    </Stack>
                </Paper>
                <br/>
                <Link to="/">Back to Home</Link>
            </Stack>
        </Container>
    )
}