import React, { useState, useEffect, useRef } from 'react';
import { renderPost } from '../api/index';
import { useParams } from 'react-router-dom';
import "../css/Michels.css"
import { getPagesApi, addPageApi, deletePageApi, sendCourseImage, sendCoursePDF } from '../api/index';
import '../css/Accounts.css'


const PdfUploader = ({ pageId }) => {
    const fileInputRef = useRef();
  
    const uploadPdf = async (file) => {
      if (!file) {
        alert('No file selected.');
        return;
      }
  
      try {
        // Using the sendCoursePDF function to send the file
        console.log(file)
        const formData = new FormData();
        formData.append('pdf', file);
        const response = await sendCoursePDF(formData, pageId);

      if (response.status == 200) {
        alert('PDF uploaded successfully!');
      } else {
        alert('Failed to upload PDF');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error uploading PDF');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    uploadPdf(file);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        accept="application/pdf"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <button onClick={handleClick}>Upload PDF</button>
    </div>
  );
};



const ImageUploader = ({ pageId }) => {
    const fileInputRef = useRef();
  
    const uploadImage = async (file) => {
      if (!file) {
        alert('No file selected.');
        return;
      }
  
      const formData = new FormData();
      formData.append('image', file);
  
      try {
        const response = await sendCourseImage(formData, pageId);
  
        if (response.status == 200) {
          alert('Image uploaded successfully!');
        } else {
          alert('Failed to upload image');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Error uploading image');
      }
    };
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      uploadImage(file);
    };
  
    const handleClick = () => {
      fileInputRef.current.click();
    };
  
    return (
      <div>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <button onClick={handleClick}>Upload Image</button>
      </div>
    );
  };

export default function AddPages() {
    const [pages, setPages] = useState([]);
    const [toAdd, setToAdd] = useState('');
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('');
    const [pageType, setPageType] = useState('dynamic'); 
    useEffect(() => {
        const getPages = async () => {
            try {
                const response = await getPagesApi();
                console.log(response.data);
                setPages(response.data);
              } catch (error) {
                console.error('Error:', error);
              }

        };
        getPages();
      }, []);


     const addPage = async () => {
        const token = localStorage.getItem('authToken');
        const data = {
            token: token,
            name: toAdd,
            type: pageType,
            price: price,
            description: description,
        };
        try {
            const response = await addPageApi(data);
            console.log(response.data);
            window.location.reload();
          } catch (error) {
            console.error('Error:', error);
          }
     };

     const deletePage = async (pageId) => {
        const token = localStorage.getItem('authToken');
        const data = {
            token: token,
            id: pageId,
        };
        try {
            const response = await deletePageApi(data);
            console.log(response.data);
            setPages(pages.filter(page => page.id !== pageId)); // Update the pages state to remove the deleted page
            window.location.reload();
        } catch (error) {
            console.error('Error:', error);
        }
    };



     return (
        <div>
            <input 
                type="text" 
                value={toAdd} 
                onChange={(e) => setToAdd(e.target.value)} 
                placeholder="Enter page name"
            />
            <div>
                <input 
                    type="text" 
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)} 
                    placeholder="Enter course description"
                />
            </div>
            <div>
                <input 
                    type="text" 
                    value={price} 
                    onChange={(e) => setPrice(e.target.value)} 
                    placeholder="Enter course price"
                />
            </div>
            <div>
                <label>
                    <input 
                    type="radio" 
                    value="dynamic" 
                    checked={pageType === 'dynamic'} 
                    onChange={() => setPageType('dynamic')} 
                    />
                    Dynamic
                </label>
                <label>
                    <input 
                    type="radio" 
                    value="static" 
                    checked={pageType === 'static'} 
                    onChange={() => setPageType('static')} 
                    />
                    Static
                </label>
            </div>
            <button onClick={addPage}>Add Page</button>     
            <ul>
                {pages.map((page, index) => (
                    <li key={index}>
                        {page.name}
                        <button className="delete-button" onClick={() => deletePage(page.id)}>Delete</button>
                        <ImageUploader pageId={page.id}/>
                        <PdfUploader pageId={page.id}/>

                    </li>
                ))}
            </ul>
        </div>
    );
}
