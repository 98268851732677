import React, { useState } from 'react';
import "../css/Accounts.css"
import { registerUser, signInUser } from '../api';
import ReCAPTCHA from "react-google-recaptcha";
import { Button, TextField, FormControlLabel, Checkbox, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Accounts ()
{
    const [recaptchaResponse, setRecaptchaResponse] = useState(null); 
    const [regName, setRegName] = useState('');
    const [regPwd, setRegPwd] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState('');
    const nav = useNavigate();

    const handleRecaptchaChange = (value) => {
      setRecaptchaResponse(value);
    };

    const sendReg = async () => {
        const data = {
            name: regName,
            email: regName,
            password: regPwd,
            recaptchaResponse: recaptchaResponse,
        };
        console.log(data);
        try {
          const response = await registerUser(data);
          setSuccess(`You registered as ${regName}!`);
          console.log(response);
        } catch (error) {
          setSuccess(`Registration failed.`);
          console.error('Error:', error);
        }
      };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        sendReg();
    };

    const sendSignIn = async () => {
        const data = {
            name: username,
            password: password,
        };
        console.log(data);
        try {
          const response = await signInUser(data);
          localStorage.clear();
          localStorage.setItem('authToken', response.data.token);
          localStorage.setItem('name', username);
          localStorage.setItem('admin', response.data.admin);
          setSuccess(`You logged in as ${username}!`);
          nav('/');
          window.location.reload();
          
          console.log(response);
        } catch (error) {
          setSuccess("Log in Failed!");
          console.error('Error:', error);
        }
      };
    
    const handleSignInSubmit = (e) => {
        e.preventDefault();
        sendSignIn();
    };

    return (
      <Container maxWidth="sm">
        <Typography variant="h4" align="center" gutterBottom>
          Register
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email or Username"
            type="text"
            value={regName}
            onChange={(e) => setRegName(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={regPwd}
            onChange={(e) => setRegPwd(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <ReCAPTCHA
            sitekey="6Lcl7EEpAAAAAMHEZDFGOLVQetZH-8H_zSWN2AzO"
            onChange={handleRecaptchaChange}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Register
          </Button>
        </form>
        <br />
        <Typography variant="h4" align="center" gutterBottom>
          Sign In
        </Typography>
        <form onSubmit={handleSignInSubmit}>
          <TextField
            label="Email or Username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Sign In
          </Button>
        </form>
        {success && (
          <Typography variant="subtitle1" color="success" align="center">
            {success}
          </Typography>
        )}
        <br />
        <Button variant="outlined" onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}>
          Log out
        </Button>
      </Container>
    );
}

              

