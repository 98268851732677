import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getPagesApi, createPaymentIntent } from '../api/index';
import '../css/Pay.css';
import {getToken, isLoggedIn} from "../api/userAuth";
import { useNavigate } from 'react-router-dom';


const stripePromise = loadStripe('pk_live_51Lsbr5JCMXrkQlvrbJOLD79CQbEcxhEjAUPGE92dSQnYZPJSyfkcBRGeh0kC9H3q0EHYnjoRwxCh0syttlK8m6Oe00whSIMOLD');


const SignInOverlay = () => (
  <div className="overlay">
    <div className="overlay-content">
      <p>You must sign in to buy a course!</p>
      <a href="/accounts">Sign In</a>
    </div>
  </div>
);

const CheckoutForm = ({ priceId }) => {
  const navigate = useNavigate();
  const createPaymentIntent_ = async (paymentMethodId, price) => {
    try {

      const paymentIntentResponse = await createPaymentIntent({
        token: getToken(),
        paymentMethodId: paymentMethodId,
        priceId: priceId,
      });
  
      if (paymentIntentResponse.error) {
        console.log('Payment Intent Creation Failed:', paymentIntentResponse.error);
      } else {
        console.log('Payment Intent Created:', paymentIntentResponse);
        
        //navigate to the course here.
        navigate('/');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error occurred: ' + error.message);
    }
  };
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      alert('Error occurred: ' + error.message);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      await createPaymentIntent_(paymentMethod.id);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>Pay</button>
    </form>
  );
};

const Pay = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [priceId, setPriceId] = useState('');
  const [courses, setCourses] = useState([
    { id: '20', name: 'Course 1', price: 20 },
    { id: '30', name: 'Course 2', price: 30 },
    { id: '40', name: 'Course 3', price: 40 },
  ]);

  useEffect(() => {
    const getPages = async () => {
        try {
            const response = await getPagesApi();
            console.log(response.data);
            let dynpage = []
            for (let i = 0; i < response.data.length; i++)
            {
                if (response.data[i].type == 'dynamic')
                {
                  dynpage.push(response.data[i]);
                }
            }
            console.log(dynpage);
            setCourses(dynpage);
          } catch (error) {
            console.error('Error:', error);
          }

    };
    getPages();
  }, []);

  const handleCourseSelection = (event) => {
    console.log(event.target);
    setSelectedCourse(event.target.value);
    // Assume each course has a different price ID
    setPriceId(event.target.value);
  };

  return (
    <div>
      {(isLoggedIn()==false) && <SignInOverlay />}
      {(isLoggedIn()==true) && (
      <div className="pay-container">
        <div className="pay-content">
          <h1 className="pay-title">Payment Gateway</h1>
          <p className="pay-description">Select a course to proceed with payment:</p>
          <select className="select-course" onChange={handleCourseSelection} value={selectedCourse}>
            <option value="">Select a Course</option>
            {courses.map(course => (
              <option key={course.id} value={course.id}>
                {`${course.name} - $${course.price}`}
              </option>
            ))}
          </select>

          {selectedCourse && (
            <Elements stripe={stripePromise}>
              <CheckoutForm priceId={priceId} />
            </Elements>
          )}
        </div>
      </div>
      )}
    </div>
  );
};

export default Pay;
