import './UIs/style.css';
import {TopBar} from "./UIs/TopBar";
import {TopBar2} from "./UIs/TopBar";
import {BottomBar} from "./UIs/BottomBar";
import Writings from "./Pages/Writings";
import BookRev from "./Pages/BookRev";
import Contact from "./Pages/Contact";
import ReadingGroup from "./Pages/ReadingGroup";
import About from "./Pages/About";
import Videos from "./Pages/Videos";
import Recent from "./Pages/Recent";
import Game from "./Pages/Game";
import {Helmet} from "react-helmet";
import PhilosophyOfHBD from "./Pages/Essays/PhilosophyOfHBD";
import MoscaReview from "./Pages/Essays/Mosca";
import MichelsReview from "./Pages/Essays/Michels";
import Centralization from "./Pages/Essays/Centralization";
import Dysgenics from "./Pages/Essays/Dysgenics";
import PowerSingularity from "./Pages/Essays/PowerSingularity";
import Hypotheses from "./Pages/Essays/Hypotheses";
import GeneticTheory from "./Pages/Essays/GeneticTheory";
import BlogPosts from "./Pages/BlogPosts";
import Secret from "./Pages/Secret";
import OnPower from "./Pages/Essays/OnPower";
import MindAndSociety from "./Pages/Essays/MindAndSociety";
import PoliticalAgency from "./Pages/Essays/PoliticalAgency";
import Managerial from "./Pages/Essays/Managerial";
import VerifyingPareto1 from "./Pages/Essays/VerifyingPareto1";
import Accounts from"./Pages/Accounts";
import PostEditor from "./PostEditor/PostEditor";
import ViewCourses from "./PostEditor/ViewCourses";
import Post from "./PostEditor/Post";
import EditPost from './PostEditor/EditPost';
import AddPages from './AddPages/AddPages';
import DynamicPage from './AddPages/DynamicPage';
import StaticPage from './AddPages/StaticPage';
import StaticEditor from './AddPages/StaticEditor';
import Pay from './Pay/Pay';
import CreatePoll from './createpoll/CreatePoll';
import TakePoll from './takepoll/TakePoll';
import UserResponse from './viewresults/UserResponse';
import EditOrder from './AddPages/EditOrder';
import UpdatePoll from './updatepoll/UpdatePoll';
import MyCourses from './PostEditor/MyCourses';


import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'; 



function Application() 
{       
        return  (
            <Router>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>HBD Academy</title>
                    <link rel="canonical" href="https://hbd.academy"/>
                    <meta name="The writings of Joseph Bronski" content="Recent Writings - Joseph Bronski"/>
                </Helmet>
                <TopBar/>
                <TopBar2/>
                <Routes>
                    <Route path="/" element={<ViewCourses/>}/>
                    <Route path="/oldRecent" element={<Recent/>}/>
                    <Route path="/writings" element={<Writings/>}/>
                    <Route path="/bookrev" element={<BookRev/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/readinggroup" element={<ReadingGroup/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/videos" element={<Videos/>}/>
                    <Route path="/blog" element={<BlogPosts/>}/>
                    <Route path="/game" element={<Game/>}/>
                    <Route path="/PhilosophyOfHBD" element={<PhilosophyOfHBD/>}/>
                    <Route path="/MoscaReview" element={<MoscaReview/>}/>
                    <Route path="/MichelsReview" element={<MichelsReview/>}/>
                    <Route path="/Centralization" element={<Centralization/>}/>
                    <Route path="/Dysgenics" element={<Dysgenics/>}/>
                    <Route path="/PowerSingularity" element={<PowerSingularity/>}/>
                    <Route path="/Hypotheses" element={<Hypotheses/>}/>
                    <Route path="/GeneticTheory" element={<GeneticTheory/>}/>
                    <Route path="/Secret" element={<Secret/>}/>
                    <Route path="/OnPower" element={<OnPower/>}/>
                    <Route path="/MindAndSociety" element={<MindAndSociety/>}/>
                    <Route path="/PoliticalAgency" element={<PoliticalAgency/>}/>
                    <Route path="/Managerial" element={<Managerial/>}/>
                    <Route path="/verifying-pareto-1" element={<VerifyingPareto1/>}/>
                    <Route path="/accounts" element={<Accounts/>}/>
                    <Route path="/makePost" element={<PostEditor/>}/>
                    <Route path="/makeTest" element={<CreatePoll/>}/>
                    <Route path="/post/:short_title" element={<Post/>}/>
                    <Route path="/editPost/:short_title" element={<EditPost/>} />
                    <Route path="/addpage" element={<AddPages/>} />
                    <Route path="/page/:page_name" element={<DynamicPage/>} />
                    <Route path="/static/:page_name" element={<StaticPage/>} />
                    <Route path="/edit_static/:page_name" element={<StaticEditor/>} />
                    <Route path="/pay" element={<Pay/>} />
                    <Route path="/poll/:short_title" element={<TakePoll/>}/>
                    <Route path='/view/:short_title' element={<UserResponse/>}/>
                    <Route path='/editOrder/:page_name' element={<EditOrder/>}/>
                    <Route path='/editPoll/:short_title' element={<UpdatePoll/>}/>
                    <Route path='/mycourses' element={<MyCourses/>}/>
                </Routes>
                <BottomBar/>
            </Router>
        );
}

export default Application; 