import axios from 'axios';

const uri = process.env.REACT_APP_API_URL;

const getAccessToken = localStorage.getItem('authToken') !== undefined ? localStorage.getItem('authToken') : localStorage.clear();

const token = getAccessToken;

const headers = {
  Authorization: `Bearer ${token}`,  // Getting the token from local storage
  'Content-Type': 'multipart/form-data'
};

//All API calls to the backend 

export const helloWorld = () => axios.get(`${uri}/`);

export const registerUser = (props) => axios.post(`${uri}/register`, props);

export const signInUser = (props) => axios.post(`${uri}/signin`, props );

export const sendPost = (props) => axios.post(`${uri}/newPost`, props);

export const getPosts = () => axios.get(`${uri}/getPosts`);

export const renderPost = (title_short) => axios.get(`${uri}/post/${title_short}`, {headers});

export const checkPDF = (id) => axios.get(`${uri}/checkPDF/${id}`);

export const uploadPDF = (props) => axios.post(`${uri}/uploadPDF`, props);

export const downloadPDF = (id) => axios.get(`${uri}/downloadpdf/${id}`);

export const deletePost = (props) => axios.post(`${uri}/deletePost`, props);

export const updatePost = (props) => axios.post(`${uri}/updatePost`, props);

export const getPagesApi = () => axios.get(`${uri}/getPages`);

export const getMyPagesApi = () => axios.get(`${uri}/getMyPages`, {headers: {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
}});

export const addPageApi = (props) => axios.post(`${uri}/addPage`, props);

export const deletePageApi = (props) => axios.post(`${uri}/deletePage`, props);

export const getPostsByPageApi = (page_name) => axios.get(`${uri}/posts_by_page/${page_name}`, { headers });

export const getStaticPageByName = (page_name) => axios.get(`${uri}/static_page_by_name/${page_name}`);

export const postStaticApi = (props) => axios.post(`${uri}/post_static`, props);

export const uploadImageFile = (formData) => axios.post(`${uri}/uploadImage`, formData, {
  headers: {
      'Content-Type': 'multipart/form-data'
  }
});

export const submitNewPollApi = (props) => axios.post(`${uri}/submitNewPoll`, props);

export const getPollsByPageApi = (page_name) => axios.get(`${uri}/polls_by_page/${page_name}`, { headers });

export const getPollResponseByUserApi = (props) => axios.post(`${uri}/getPollResponseByUser`, props);

export const submitPollResponseByUserApi = (props) => axios.post(`${uri}/submitPollResponseByUser`, props);

export const getNextCatApi = (props) => axios.post(`${uri}/getNextCat`, props);

export const getPollByShortTitleApi = (short_title) => axios.get(`${uri}/getPollByShortTitle/${short_title}`);

export const removePollResponseByUserApi = (props) => axios.post(`${uri}/removePollResponseByUser`, props);

export const getOrderByPageApi = (page_name) => axios.get(`${uri}/order_by_page/${page_name}`);

export const submitOrderApi = (props) => axios.post(`${uri}/submitOrder`, props);

export const deletePollApi = (props) => axios.post(`${uri}/removePoll`, props);

export const updatePollApi = (props) => axios.post(`${uri}/updatePoll`, props);

export const createPaymentIntent = (props) => axios.post(`${uri}/create-payment-intent`, props);

export const sendCoursePDF = (file, pageId) => {
  
  return axios.post(`${uri}/upload-course-pdf/${pageId}`, file, {
    headers: headers,
  });
};

export const sendCourseImage = (file, pageId) => {

  return axios.post(`${uri}/upload-course-image/${pageId}`, file, {
    headers: headers,
  });
};