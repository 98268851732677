import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPostsByPageApi, getPollsByPageApi, getOrderByPageApi, submitOrderApi } from '../api/index';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "../css/Michels.css"
import {Button} from "@mui/material";
import {getToken} from "../api/userAuth";
import { useNavigate } from 'react-router-dom';

export default function EditOrder() {
    const nav = useNavigate();
    const { page_name } = useParams();
    const [order, setOrder] = useState([]);
    const admin = localStorage.getItem('admin');
      useEffect(() => {
        const getOrder = async () => {
          const sortCombinedItems = (posts, polls, order) => {
            const combinedItems = [...posts, ...polls];
            return order.map(o => combinedItems.find(item => item.short_title === o)).filter(Boolean);
        };
            try {
                const response3 = await getOrderByPageApi(page_name);
                setOrder(response3.data);
              } catch (error) {
                console.error('Error:', error);
              }

        };
        getOrder();
      }, []);

      const onDragEnd = (result) => {
        if (!result.destination) {
            return; // dropped outside the list
        }

        const newOrder = Array.from(order);
        const [reorderedItem] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, reorderedItem);

        setOrder(newOrder);
        // Here, send the updated order to your backend
    };

    const submitClick = async () => {
        try {
            const data = {
                token: getToken(),
                order: order,
                page_name: page_name,
            }
            const response = submitOrderApi(data);
            nav(`/page/${page_name}`);
        } catch (error) {
            console.log(error);
        }

    }
    return (
        <div className='Writings'>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div className='Writings-text' {...provided.droppableProps} ref={provided.innerRef}>
                            <h1 id='Alpha'>ORDERED CONTENT</h1>
                            {order.map((itemTitle, index) => (
                                <Draggable key={itemTitle} draggableId={itemTitle} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {itemTitle}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Button variant="contained" sx={{width: "fit-content"}} onClick={submitClick}>Submit
                            Order</Button>
        </div>
        );
}
