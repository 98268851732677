import React, { useState, useEffect } from 'react';
import { getPagesApi } from '../api/index';  // adjust the path if needed
import { Container, Typography, Card, CardContent, Link, Grid, Link as MuiLink  } from '@mui/material';
import Testimonials from './Testimonials';


const ViewCourses = () => {
  const [pages, setPages] = useState([]);


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const getPages = async () => {
        try {
            const response = await getPagesApi();
            console.log(response.data);
            let dynPage = []
            for (let i = 0; i < response.data.length; i++)
            {
                if (response.data[i].type == 'dynamic')
                {
                  dynPage.push(response.data[i]);
                }
            }
            setPages(dynPage);
          } catch (error) {
            console.error('Error:', error);
          }

    };
    getPages();
  }, []);

  const downloadPdf = (base64String, fileName) => {
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom align="center" color="textPrimary">
        All Products
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {pages.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <Link href={`/page/${course.name.toLowerCase()}`} color="inherit" underline="none">
              <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)' // Adjust the color for the hover state
                }
              }}>
                {course.image && (
                  <img 
                    src={`data:image/jpeg;base64,${course.image}`} 
                    alt={course.name} 
                    style={{ width: '100%', height: 'auto' }} 
                  />
                )}
                <CardContent>
                  <Typography variant="h5" component="h2" align="center" gutterBottom>
                    {course.name}
                  </Typography>
                  <Typography variant="body1" color="textSecondary" align="center">
                    {course.description}
                  </Typography>
                  <Typography variant="body2" color="textPrimary" align="center">
                    {'$' + capitalizeFirstLetter(course.price)}
                  </Typography>
                  {course.pdf && (
                  <MuiLink
                    component="button"
                    variant="body2"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent navigation
                      downloadPdf(course.pdf, `${course.name}.pdf`);
                    }}
                    style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                  >
                    Syllabus
                  </MuiLink>
                )}
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
      <br></br>
      <br></br>
      
    </Container>
  );
};

export default ViewCourses;
/*
{posts.map(post => (
        <div key={post.id} className='LinkAndSummary'>
            <h1 id='myh1'>{post.date}</h1>
            <p id='p1'> <a href={`/post/${post.title_short}`}>{post.title}</a> </p>
            <br></br>
            <p id='p1'>{post.description}</p>
            <p id="p2">
              {post.postPages.map( (page, index) => (
                <React.Fragment key={index}>
                <a href={'page/' + page}>{capitalizeFirstLetter(page)}</a>{'\t\t'}
                </React.Fragment>
              ))}
            </p>
            <br></br>
    
        </div>
      ))}
*/