import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPostsByPageApi, getPollsByPageApi, getOrderByPageApi, deletePollApi } from '../api/index';
import "../css/Michels.css"
import { getToken } from '../api/userAuth';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Card, CardContent, Link, Button, Grid } from '@mui/material';


export default function DynamicPage() {
    const { page_name } = useParams();
    const [items, setItems] = useState([]);
    const admin = localStorage.getItem('admin');
    const nav = useNavigate();
      useEffect(() => {
        const getOrder = async () => {
          const sortCombinedItems = (posts, polls, order) => {
            for (let i = 0; i < posts.length; i++)
            {
              posts[i].type = 'post';
            }
            const combinedItems = [...posts, ...polls];
            return order.map(o => combinedItems.find(item => item.short_title === o)).filter(Boolean);
        };
            try {
                const response1 = await getPostsByPageApi(page_name); 
                if (response1.data.message == 'Unpaid')
                {
                  nav('/pay')
                }
                const response2 = await getPollsByPageApi(page_name);
                const response3 = await getOrderByPageApi(page_name);
                const combined = sortCombinedItems(response1.data, response2.data, response3.data);
                setItems(combined);
              } catch (error) {
                console.error('Error:', error);
              }

        };
        getOrder();
      }, []);

    const handleDeleteClick = (short_title) => {
      const data = {
        short_title: short_title,
        token: getToken(),
      }
      if (window.confirm('Are you sure you want to delete this poll?')) {
        deletePollApi(data).then(() => {
          window.location.reload();
      })
      .catch(error => {
          console.error('Deletion failed: ', error);
      });
      }

    }
    return (
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Course Content
        </Typography>
        {items.map(item => (
          <Link href={`/${item.type === 'post' ? 'post' : 'poll'}/${item.short_title}`} color="inherit" underline="none" key={item.id || item._id}>
            <Card sx={{
              marginBottom: 2,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)' // Adjust the color for the hover state
              }
            }}>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  {item.title}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {item.description || item.details}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {item.type === 'post' ? 'Module' : 'Quiz'}
                </Typography>
                {item.type !== 'post' && admin === 'true' && (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button variant="text" size="small" href={`/editPoll/${item.short_title}`}>
                        Edit
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="text" size="small" onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteClick(item.short_title);
                      }}>
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Link>
        ))}
        {admin === 'true' && (
          <Button variant="contained" color="primary" href={`/editOrder/${page_name}`} sx={{ marginTop: 2 }}>
            Edit Order
          </Button>
        )}
      </Container>
    );
}
