import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import bellCurve from "highcharts/modules/histogram-bellcurve";

bellCurve(Highcharts);

export class BellCurve extends React.Component {

    constructor(props) {
        super(props);

        const score = props.scores.score;
        const mean = props.scores.mean;
        const std = props.scores.std;

        this.state = {
            config: {
                title: {
                    text: `Your score: ${score}`
                },

                legend: {
                    enabled: false
                },

                xAxis: [
                    {
                        visible: false
                    },
                    {
                        title: {
                            text: "Bell curve"
                        },
                        visible: true,
                        plotLines: [{
                            color: '#FF0000',
                            width: 2,
                            value: score
                        }]
                    }
                ],

                yAxis: [
                    {
                        visible: false
                    },
                    {
                        visible: false
                    }
                ],

                series: [
                    {
                        name: "Normal Distribution",
                        type: "bellcurve",
                        xAxis: 1,
                        yAxis: 1,
                        baseSeries: 1,
                        zIndex: -1,
                    },
                    {
                        type: "scatter",
                        data: [mean - std, mean, mean + std],
                        visible: false,
                    }
                ]
            }
        };
    }

    render() {
        return (
            <HighchartsReact
                constructorType={"chart"}
                highcharts={Highcharts}
                options={this.state.config}
            />
        );
    }
}

